/** CONTAINER STYLES **/

.DateRangePickerInput {
  @apply flex border-0 bg-transparent;
}

.DateRangePicker_picker {
  @apply -mt-1;
}

/** INPUT DIV STYLES **/

.DateInput {
  background: transparent;
  @apply flex w-auto flex-1;
}

/** INPUT FIELD COMMON STYLES **/

.DateInput_input {
  /* used data:image as background-image because svg import with relative url didn't work */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="%236b6b6b" fill-rule="evenodd"><path d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"/></g></svg>');

  background-size: 13px;
  background-position: 10px center;
  @apply bg-no-repeat;
}
.DateInput_input {
  @apply border-common-light w-full cursor-pointer appearance-none rounded border-t border-l border-r border-b border-solid bg-primary-main py-1 px-1 pl-8 text-sm font-light leading-tight text-white shadow transition duration-300;
}
.DateInput_input:hover {
  @apply border-gray-500;
}
.DateInput_input:focus {
  @apply border-gray-500 outline-none;
}
/** FIRST INPUT STYLES **/
.DateInput:first-child .DateInput_input {
  @apply rounded-r-none;
}

.DateInput:first-child .DateInput_input:hover,
.DateInput:first-child .DateInput_input:focus {
  @apply relative z-10;
}

/** SECOND INPUT STYLES **/
.DateInput:last-child .DateInput_input {
  @apply rounded-l-none;
  margin-left: -1px;
}
/** ARROW STYLES **/
.DateRangePickerInput_arrow {
  @apply hidden;
}

/* SELECT MONTH PICKER */
.DateRangePicker_select {
  @apply text-secondary-active border-common-dark cursor-pointer appearance-none rounded border bg-common-light py-1 pl-2 pr-5 text-base; /* NEEDED FOR ARROW DOWN */
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
    calc(100% - 11px) 11px,
    calc(100% - 6px) calc(11px);
  background-size:
    5px 5px,
    5px 5px;
  background-repeat: no-repeat;
}
/* CALENDAR DAYS */
.CalendarDay {
  @apply rounded-full border-0;
}

.CalendarDay:hover,
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  @apply bg-primary-main border-primary-main border-0 text-common-light;
}

.CalendarDay__blocked_out_of_range:hover {
  @apply text-common-dark cursor-not-allowed border-0 bg-common-light;
}

.CalendarDay__selected_span {
  @apply bg-primary-light border-0;
}

/* MONTH NAVIGATION BUTTONS */
.DayPickerNavigation_button__horizontalDefault,
.DayPickerNavigation_button__horizontalDefault:hover {
  @apply border-common-dark text-common-dark;
  top: 24px;
  padding: 3px 9px;
}
.DayPickerNavigation_svg__horizontal {
  @apply fill-current;
}
