/** CONTAINER STYLES **/

.nice-dates-popover -open {
    @apply flex border-0 bg-transparent rounded-none !w-80;
  }
  .nice-dates-popover  {
    @apply !w-80;
  }
.nice-dates-navigation {
    @apply flex border-0 bg-transparent;
  }
.nice-dates input {
    @apply shadow transition duration-300 appearance-none border bg-primary-main border-common-light focus:border-primary-light hover:border-primary-light focus:outline-none rounded !p-1 text-sm text-common-light placeholder-common-light leading-tight
  }
  .nice-dates-day {
    @apply w-10 h-10
  }
  .nice-dates-day .-selected {
    @apply bg-primary-light
 }