/* CUSTOM OHIF SCROLLBAR */
.saola-scrollbar {
  scrollbar-color: #173239 transparent;
  scrollbar-gutter: stable;
}

.study-min-height {
  min-height: 450px;
}

.saola-scrollbar:hover {
  overflow-y: auto;
}

.saola-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.saola-scrollbar::-webkit-scrollbar-track {
  @apply rounded;
}

.saola-scrollbar::-webkit-scrollbar-thumb {
  @apply rounded;
  @apply bg-primary-main;
  background-color: #173239;
}

.saola-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  @apply bg-primary-main;
  background-color: #173239;
}

/* INVISIBLE SCROLLBAR */
.invisible-scrollbar {
  scrollbar-width: none;
}

.invisible-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

.invisible-scrollbar::-webkit-scrollbar-track {
  @apply hidden;
}

.invisible-scrollbar::-webkit-scrollbar-thumb {
  @apply hidden;
}

.invisible-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  @apply hidden;
}

/* CUSTOM SCROLLBAR */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 2px;
  background: #cccccc;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #2B568A;
  border-radius: 2px;
  border: 2px solid #2B568A;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #2B568A;
}

.custom-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  background-color: #2B568A;
}

.square-radio {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  border-radius: 2px 2px 2px 2px;
  position: relative;
}

.square-radio:checked::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
}