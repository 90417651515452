.customSelect__wrapper .customSelect__control {
  @apply border-common-light w-full rounded border bg-primary-main text-sm leading-tight text-white shadow transition duration-300 ;
  min-width: 60px;
}
.customSelect__wrapper .customSelect__control:hover {
  @apply border-gray-500;
}

.customSelect__wrapper .customSelect__control:focus,
.customSelect__wrapper .customSelect__control--menu-is-open {
  @apply border-gray-500 outline-none;
}

.customSelect--is-disabled .customSelect__control--is-disabled {
  @apply pointer-events-none;
}

.css-1hwfws3 {
  padding: 0;
}

.customSelect__wrapper .customSelect__indicator-separator {
  @apply hidden;
}

.customSelect__wrapper .customSelect__dropdown-indicator {
  padding: 0px;
}

.customSelect__wrapper .customSelect__option {
  color: #d6d6d6;
  font-size: 13px;
  display: flex;
  align-items: center;
  @apply flex flex-row items-center;
}

.customSelect__wrapper .customSelect__option--is-selected {
  @apply bg-transparent;
}

.customSelect__wrapper .customSelect__single-value {
  @apply text-aqua-pale pl-1;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.customSelect__wrapper.customSelect--is-disabled {
  @apply pointer-events-auto cursor-not-allowed;
}

.customSelect__wrapper .customSelect__value-container--is-multi {
  @apply inline-block truncate px-1 py-1;
}

.ohif-select .customSelect__menu {
  background-color: transparent !important;
  border-radius: 6px;
}

.ohif-select .customSelect__menu-list {
  overflow-x: hidden !important;
  margin: 4px;
  padding-top: 0px !important;
}

.ohif-select .customSelect__menu-list::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}
.ohif-select .customSelect__placeholder {
  @apply text-aqua-pale pl-1.5;
}

.ohif-select .customSelect__menu-list::-webkit-scrollbar-thumb {
  background-color: #0944b3;
  border-radius: 10px;
}

.customSelect__option--is-focused {
  background-color: #0944b391 !important;
}

.ohif-select .customSelect__option:hover {
  background-color: #0944b391;
}

.ohif-select .customSelect__menu {
  border: 2px solid #0944b3;
  border-radius: 6px;
  background-color: #151515 !important;
}

.css-1hwfws3 {
  all: unset !important;
}

.customSelectWhiteColor__wrapper .customSelectWhiteColor__control {
  @apply border-common-light w-full rounded border bg-primary-main text-sm leading-tight text-common-light shadow transition duration-300;
  min-height: 28px;
}
.customSelectWhiteColor__wrapper .customSelectWhiteColor__control:hover {
  @apply border-primary-light;
}

.customSelectWhiteColor__wrapper .customSelectWhiteColor__control:focus,
.customSelectWhiteColor__wrapper .customSelectWhiteColor__control--menu-is-open {
  @apply border-gray-500 outline-none;
}

.customSelectWhiteColor--is-disabled .customSelectWhiteColor__control--is-disabled {
  @apply pointer-events-none;
}

.customSelectWhiteColor__wrapper .customSelectWhiteColor__indicator-separator {
  @apply hidden;
}

.customSelectWhiteColor__wrapper .customSelectWhiteColor__dropdown-indicator {
  padding: 0;
}

.customSelectWhiteColor__wrapper .customSelectWhiteColor__option--is-selected {
  @apply bg-transparent;
}

.customSelectWhiteColor__wrapper:hover .customSelectWhiteColor__dropdown-indicator {
  color: #3A73B8;
}

.customSelectWhiteColor__wrapper .customSelectWhiteColor__option {
  color: #cccccc;
  font-size: 14px;
  display: flex;
  align-items: center;
  @apply flex flex-row items-center;
}

.customSelectWhiteColor__wrapper .customSelectWhiteColor__single-value {
  @apply text-common-light pl-1;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.customSelectWhiteColor__wrapper.customSelectWhiteColor--is-disabled {
  @apply pointer-events-auto cursor-not-allowed;
}

.customSelectWhiteColor__wrapper .customSelectWhiteColor__value-container--is-multi {
  @apply inline-block truncate px-3 py-2;
}

.ohif-select .customSelectWhiteColor__menu-list {
  overflow-x: hidden !important;
  padding-top: 0px !important;
}

.ohif-select .customSelectWhiteColor__placeholder {
  @apply text-common-light pl-1.5;
}

.customSelectWhiteColor__option--is-focused {
  background-color: #3A73B8 !important; 
}

.customSelectWhiteColor__option {
  padding: 0 8px !important;
  margin: 0 !important;
}

.ohif-select .customSelectWhiteColor__option:hover {
  background-color: #3A73B8;
}

.ohif-select .customSelectWhiteColor__menu {
  border: 1px solid #2c2c2c;
  background-color: #2c2c2c !important;
  
}

.customSelectWhiteColor__input-container {
  padding: 0 !important;
}

.customSelectWhiteColor__value-container {
  padding: 0 !important;
}

.customSelectWhiteColor__menu-list::-webkit-scrollbar {
  width: 6px;
}

.customSelectWhiteColor__menu-list::-webkit-scrollbar-track {
  border-radius: 2px;
  background: #cccccc;
}

.customSelectWhiteColor__menu-list::-webkit-scrollbar-thumb {
  background: #2B568A !important;
  border-radius: 2px;
  border: 2px solid #2B568A;
}
