/* IMPORT CUSTOM FONT */
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Kanit';
  src: url('../../../public/fonts/Kanit-Regular.woff2') format('woff2'),
  url('../../../public/fonts/Kanit-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@layer base {
  html {
    font-family: 'Kanit', sans-serif;
  }
}

body {
  background-color: #000;
}

/* Truncate 2 lines utility */
.truncate-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3;
  height: 2.1rem;
}


