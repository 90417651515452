.spinner {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    display:grid;
    -webkit-mask: conic-gradient(from 15deg,#0000,#000);
    animation: s6 1s infinite steps(12);
  }
  .spinner,
  .spinner:before,
  .spinner:after{
    background:
      radial-gradient(closest-side at 50% 12.5%,
      #ff9900 100%,#0000) 50% 0/20% 80% repeat-y,
      radial-gradient(closest-side at 12.5% 50%,
      #ff9900 100%,#0000) 0 50%/80% 20% repeat-x;
  }
  .spinner:before,
  .spinner:after {
    content: "";
    grid-area: 1/1;
    transform: rotate(30deg);
  }
  .spinner:after {
    transform: rotate(60deg);
  }
  
  @keyframes s6 {
    100% {transform:rotate(1turn)}
  }